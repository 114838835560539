<template>
    <div class="app-view">
        <v-container class="pt-0 mt-0">
            <loading v-if="isLoading" />

            <v-card flat class="elevation-0 u-rounded" v-if="isActivated">
                <v-card-text>
                    <p>{{$t("email_validate.body")}}</p>
                </v-card-text>
            </v-card>

            <v-alert type="error" dense icon="error_outline" v-if="isError" class="mt-2">
                {{errorText}}
            </v-alert>

        </v-container>
    </div>
</template>

<script>
import {userVerifyEmail} from '../graphql/mutations/users';

export default {
    name: 'EmailValidate',
    data: () => ({
        isLoading: false,
        isActivated: false,
        isError: false,
        errorText: ''
    }),
    methods: {
        onGotoLogin() {
            this.$router.push('/');
        },
        onValidateUser(email, token) {
            this.isLoading = true;
            this.$apollo.mutate({
                mutation: userVerifyEmail,
                variables: {
                    email: email,
                    token: token
                }
            }).then(data => {
                this.isLoading = false;
                this.isError = false;
                this.isActivated = true;
            }).catch(error => {
                this.errorText = error;
                this.isLoading = false;
                this.isError = true;
                this.isActivated = false;
            });
        }
    },
    created() {
        const url = window.location.href;
        const array = url.split('/');
        const token = array[array.length - 2];
        const email = array[array.length - 1];
        this.onValidateUser(email, token);
    }
};
</script>
